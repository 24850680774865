
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import { employeeDropdownsType, chargingFacilityType } from '@/types'
import Input from '@/components/UI/Input.vue'
import CurrencyFormat from '@/filters/CurrencyFormat'
import { useStore } from '@/store'
import { MutationTypes } from '@/store/options/mutations'
export default defineComponent({
  components: {
    Input
  },
  emits: ['onChange'],
  name: 'Dropdown',
  props: {
    employeesItems: {
      type: Array as () => employeeDropdownsType[],
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String
    },
    selectedOption: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: ''
    },
    currentIndex: {
      type: Number,
      default: null
    },
    selectedModels: {
      type: Array,
      default: () => []
    },
    selectedDropdownItems: {
      type: Array,
      default: () => []
    },
    isDuration: {
      type: Boolean,
      default: false
    },
    isMultiselect: {
      type: Boolean,
      default: false
    },
    selectedChargingDropdownItems: {
      type: Object as () => chargingFacilityType,
      default: () => ({})
    },
    chargingFacilityItems: {
      type: Array as () => chargingFacilityType[],
      default: () => []
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    isBrandsSearch: {
      type: Boolean,
      default: false
    },
    isLevelSearch: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const showAnyOption = ref(false)
    const store = useStore()
    const el = ref<HTMLDivElement>(document.createElement('div'))
    const searchInput = props.isBrandsSearch
      ? ref(store.state.options.searchString)
      : props.isLevelSearch
      ? ref(store.state.options.searchLevels)
      : ref(store.state.options.searchModels)
    const open = ref(false)
    const onChange = (item) => {
      showAnyOption.value = true
      if (!props.isMultiselect) {
        open.value = false
      }
      if (props.type == 'mileage-dropdown' || props.type == 'brandsModel-dropdown' || props.type == 'range-dropdown') {
        emit('onChange', { item: item, key: props.currentIndex })
      } else {
        emit('onChange', item)
      }
    }
    const getColor = (item) => {
      let color = 'orange'
      if (item == '#2C00AB') {
        color = 'dark-blue'
      } else if (item == '#00B5AD') {
        color = 'sky-blue'
      } else if (item == '#51B786') {
        color = 'green'
      } else if (item == '#FF675E') {
        color = 'red'
      }
      return color
    }
    const closeDropdown = (e) => {
      const target = e.target
      if (el.value && el.value !== target && !el.value.contains(target)) {
        open.value = false
      }
    }
    const onSearchInput = (value) => {
      if (props.isBrandsSearch) {
        store.commit(MutationTypes.SET_SEARCH_STRING, value)
      } else if (props.isLevelSearch) {
        store.commit(MutationTypes.SET_SEARCH_LEVELS, value)
      } else {
        store.commit(MutationTypes.SET_SEARCH_MODELS, value)
      }
    }
    onMounted(() => {
      document.addEventListener('click', closeDropdown)
    })
    onUnmounted(() => {
      document.removeEventListener('click', closeDropdown)
    })
    return { showAnyOption, open, onChange, getColor, el, CurrencyFormat, searchInput, onSearchInput }
  }
})
